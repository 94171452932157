<template>
    <div v-if="params != false">
        <b-card no-body="no-body">
            <b-row class="p-5">
                <b-col md="12" class="text-right">
                    <b-button size="sm" class="btn-icon" variant="primary" @click="add()"><i class="fas fa-plus"></i></b-button>
                    <b-button size="sm" class="btn-icon ml-1" variant="success" @click="save()"><i class="fas fa-save"></i></b-button>
                </b-col>
            </b-row>
            <b-tabs pills="pills" card="card" vertical="vertical" v-model="selected">
                <b-tab v-for="(p, i) in params" :key="i" :title="p.titre" :active="i === 0">                    
                    <b-table ref="datalist" class="table-params" stacked="md" striped hover :items="datatable" :fields="fields" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
                        <template #cell(titre)="data">                            
                            <Input type="text" v-model="data.item.titre"/>
                        </template>
                        <template #cell(color)="data">                            
                            <select class="form-control" :class="`bg-${data.value} text-white`" v-model="data.item.color">
                                <option value="primary">Primary</option>
                                <option value="secondary">Secondary</option>
                                <option value="success">Success</option>
                                <option value="warning">Warning</option>
                                <option value="info">Info</option>
                                <option value="danger">Danger</option>
                            </select>
                        </template>                        
                        <template #cell(edit)="data">                                                        
                            <b-button size="sm" class="btn-icon ml-2" variant="danger" @click="removeD(data.item)"><i class="fas fa-trash"></i></b-button>
                        </template>
                    </b-table>
                </b-tab>
                <template #tabs-end>
                    <b-button v-b-modal.modalParam variant="primary" size="sm" class="btn-icon ml-5 mt-5"><i class="fas fa-plus"></i></b-button>
                </template>
            </b-tabs>
        </b-card>
        <b-modal ref="modalParam" id="modalParam" hide-footer title="Nouveau param">
            <b-form-group label="Titre">
                <Input type="text" v-model="tmp.titre"/>
            </b-form-group>
            <b-button block variant="info" @click="tmp.data.push('')">Ajouter un nouveau champ</b-button>
            <b-form-group v-for="(d,i) in tmp.data" :key="i" class="mt-2">
                <input class="form-control" v-model="tmp.data[i]" />
            </b-form-group>
            <div class="d-block text-center mt-5">
                <b-button class="" variant="outline-primary" @click="$refs.modalParam.hide()">Fermer</b-button>
                <b-button class="ml-2" variant="primary" @click="saveP()">Sauvegarder</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import Input from '@/components/InputForm';
    export default {
        name: "ParamListe",
        data() {
            return {
                params: false, 
                selected: null,
                tmp:{
                    titre:null,
                    data:[]
                }
            }
        },
        computed:{
            fields(){
                var check = this.param;
                if(check === false){
                    return [];
                }
                var fields = [];                
                for(const [key] of Object.entries(check.data[0])){
                    fields.push({
                        key:key,
                        label: key,
                    });
                }
                fields.push({
                    key:'edit',
                    label: 'action',
                });
                return fields;
            },
            datatable(){
                var check = this.param;
                if(check === false){
                    return [];
                }
                return check.data;
            },
            param(){
                var check = this.params[this.selected];
                if(check === undefined){
                    return false;
                }
                return check;
            }
        },
        components: {
            Input
        },
        beforeMount() {
            this.$api.ajax('/param/liste', null, res => {
                if (res.status) {
                    this.params = res.data;
                }
            });
        },
        mounted() {},
        methods:{  
            saveP(){
                var tmp = {
                    id:null,
                    titre: this.tmp.titre,
                    data:[],
                }
                var t = {
                    id:1
                };
                this.tmp.data.forEach(e => {
                  t[e] = null;  
                });
                tmp.data.push(t);                
                this.params.push(tmp);
                this.$refs.modalParam.hide();
            },
            add(){
                var check = this.param;
                if(check === false){
                    return false;
                }
                var last = check.data.length - 1;                  
                var data = {};
                for(const [key, value] of Object.entries(check.data[last])){                    
                    if(key === 'id'){                        
                        data[key] = value + 1;
                    }else{
                        data[key] = null;
                    }
                }
                check.data.push(data);
            },
            removeD(d){
                var check = this.param;
                if(check === false){
                    return false;
                }
                check.data = check.data.filter(t => t.id != d.id)
            },
            save(){
                var check = this.param;
                if(check === false){
                    return false;
                }
                this.$api.ajax('/param/create', check, res => {
                    if(res.status === true){
                        this.$bvToast.toast('Sauvegarder !', {
                            title: 'Sauvegarder',
                            variant: 'success',
                            solid: true
                        });
                    }
                });
            }
        }
    };
</script>
<style>
table.table-params td {
  vertical-align: middle !important;
}
</style>